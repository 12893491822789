import { Auth } from "aws-amplify";

type Fetch = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;


async function fetchToken() {
  const sToken = (await Auth.currentSession()).getIdToken().getJwtToken();  
  return sToken;
}

let token = '';

const fetchAbsolute = (fetch: Fetch, base_url: string) => {    
  return async (url: string, init?: RequestInit | undefined) => {
    if (!token) {
      token = await fetchToken();
    }

    // Create headers once
    const headers = {
      ...init?.headers,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    
    // Use base_url only if needed
    const fullUrl = url.startsWith('http') ? url : base_url + url;
    
    return fetch(fullUrl, { ...init, headers });
  }
}
  
  export const api = fetchAbsolute(fetch, process.env.REACT_APP_API_DOMAIN!);
  