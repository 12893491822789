import { api } from "../../utils/api"
import { Stage } from "./stages.interface";

const URL = '/categories/:category/stages'

const get = async (category: string) => {  
  const response = await api(URL.replace(':category', category))
  const data: Stage = await response.json();

  return data.body.map(option => ({
    value: option,
    label: option
  }))
}

export const StagesService = {
  URL,
  get
} as const;