import { StagesService } from "../services/stages/stages.service"
import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext } from "react"
import { useAuthorization } from "./authorization"
import { useCategories } from "./categories"

type StagesType = {
    value: string
    label: string
}

type StagesContextType = {
    stages: StagesType[]
    loading: boolean
    setStages: Dispatch<{value: string, label: string}[]>
    setCurrentStage: Dispatch<SetStateAction<string>>
    currentStage: string
}

type Props = {
    children: ReactNode;
};

const stagesDefaultValues: StagesContextType = {
    stages: [],
    setStages: () => {},
    loading: true,
    setCurrentStage: () => {},
    currentStage: ""
}

const StagesContext = createContext<StagesContextType>(stagesDefaultValues);

export const useStages = () => useContext(StagesContext);

export const StagesProvider = ({ children }: Props) => {
    const { currentCategory } = useCategories();
    const [stages, setStages] = React.useState<StagesType[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [currentStage, setCurrentStage] = React.useState<string>("")
    const requestStarted = React.useRef(false);
    const { alreadyChecked } = useAuthorization()

    React.useEffect(() => {
        const fetchStages = async () => {
            requestStarted.current = true;
            const fetchedStages = await StagesService.get(currentCategory.length ? currentCategory: "wordpress");
            requestStarted.current = false;
            let stage: string;
            if(fetchedStages.find(item => item.value === "prod") !== undefined){
                stage = "prod"
            } else {
                stage = (fetchedStages[0].value);
            }
            setStages(fetchedStages);
            setLoading(false);
            setCurrentStage(stage);
        }
        fetchStages();
    }, [alreadyChecked])

    return (
        <StagesContext.Provider value={{ stages, loading, setStages, setCurrentStage, currentStage }}>
            {children}
        </StagesContext.Provider>
    )
}