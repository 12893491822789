import LatestVersionCell from '../components/latestVersionCell';

export type Column = {
    field: string;
    headerName: string;
    description?: string;
    width?: number;
    editable: boolean;
    flex?: number;
    renderCell?: (params: any) => JSX.Element;
};

const COLUMNS_TO_EXCLUDE = ['latestReleaseDate', 'lts'];

const COLUMN_DEFAULT: Column[] = [
    { editable: false, headerName: "Product", width: 200, field: "product", description: 'Shows all used and supported products in the clicked project.' },
    { editable: false, headerName: "Current Version", width: 200, field: "currentVersion", description: 'Shows the currently used version of a product.' }
];

const COLUMNS_CONFIG = {
    getWidth: (field: string) => {
        if (field === "latestReleaseDate" || field === "lts" || field === "support" || field === "eol" || field === "latest" || field === "currentVersion") return { width: 150 }
        return { flex: 1 }
    },
    mapper: (headerName: string) => {
        if (headerName === "latest") return "Latest Version"
        if (headerName === "eol") return "Security Support"
        if (headerName === "support") return "Active Support"
        if (headerName === "lts") return "LTS"
        if (headerName === "supportedPHPVersions") return "Supported PHP Versions"
        return headerName;
    },
    descriptionMapper: (headerName: string) => {
        switch(headerName) {
            case 'Active Support':
                return 'Shows the date until the version you are using is actively maintained and receives regular updates from the developers.';
            case 'Security Support':
                return 'Shows the date until the version you are using is maintained just regarding security issues.';
            case 'Latest Version':
                return 'Shows the latest accessible and stable version.';
            case 'Supported PHP Versions':
                return 'Shows all supported PHP Versions.';
        }
    }
}

export const columnsBuilder = (fields: Array<string>) => {
    return fields.reduce((acc: Column[], field: string) => {
        if (COLUMNS_TO_EXCLUDE.includes(field)) {
            return acc;
        }
        const config = COLUMNS_CONFIG.getWidth(field);
        const headerName = COLUMNS_CONFIG.mapper(field);
        const description = COLUMNS_CONFIG.descriptionMapper(headerName);

        const column: Column = {
            editable: false,
            headerName,
            field,
            description,
            ...config
        };

        // Apply custom cell renderer for 'Latest Version'
        if (field === 'latest') {
            column['renderCell'] = LatestVersionCell;
        }

        return [...acc, column];
    }, COLUMN_DEFAULT);
}
