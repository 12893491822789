import { api } from "../../utils/api";
import { ProjectsResponse, Projects } from "./projects.interface";

const URL = '/categories/:category/stages/:stage/projects'

export const ProjectsService = {
  get : async (category: string, stage: string): Promise<Record<string, Projects[]>> => {  
  const response = await api(URL.replace(":category", category).replace(":stage", stage))
  const data: ProjectsResponse = await response.json();
  
  if (!data || !data.body) {
    console.error('Invalid API response', data);
    throw new Error('Invalid API response');
  }

  const removeWhenAPiReturnsTheCorrectValue = data.body.map(project => {
    if (!project.products) {
      throw new Error('project.products is undefined');
    }

    return {
      ...project,
      products: project.products.map((product) => ({
        ...product,
        cycle: product.cycle! || product.version!
      })),
      lastModified: project.lastModified,
      description: project.description,
      lastEditFrom: project.lastEditFrom,
      lastEditAt: project.lastEditAt
    }
  })
  return { [category]: removeWhenAPiReturnsTheCorrectValue }
}
} as const;

export const updateProjectDescription = async (category: string, stage: string, project: string, description: string) => {
  try {
    const response = await api(`/categories/${category}/stages/${stage}/projects/${project}`, {
      method: 'PATCH',
      body: JSON.stringify({ description })
    });
    
    if (!response.ok) throw new Error('Failed to update description');
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getProjects = async (category: string, stage: string) => {
  try {
    const response = await api(`/categories/${category}/stages/${stage}/projects`);
    
    if (!response.ok) throw new Error('Failed to fetch projects');
    const data = await response.json();
    return data; // or return data.body if your API wraps the response
  } catch (error) {
    throw error;
  }
}
