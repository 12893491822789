import React from 'react';
import Tooltip from '@mui/material/Tooltip';

function LatestVersionCell(props: any) {
  const { value, row } = props;

  const tooltipText = `
        Latest Release Date: ${row.latestReleaseDate}
        LTS: ${row.lts}
    `;

  return (
    <Tooltip title={tooltipText}>
      <span>{value}</span>
    </Tooltip>
  );
}

export default LatestVersionCell;
